export interface User {
  id: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  userRoles: Roles[];
  azureCode: string;
  dateOfBirth: string | undefined;
  photoUrl: string;
  source: string;
  isEnabled?: boolean;
  zipCode?: string;
}

export interface Roles {
  workshopId: string;
  roleCode: RoleCode;
  isConfigured?: boolean;
}

export interface UserNotification {
  UserId: number;
  WorkshopId: number;
  NotificationCode: string;
}

export type RoleCode = 'OWN' | 'ADM' | 'MEC';

export const newUser: User = {
  id: '0',
  name: '',
  lastName: '',
  phone: '',
  email: '',
  userRoles: [],
  azureCode: '',
  dateOfBirth: undefined,
  photoUrl: '',
};
