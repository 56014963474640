export type TGlobalLayout = "default" | "mobilhub";
export const validGlobalLayout: TGlobalLayout[] = ["default", "mobilhub"];
export interface IPromotionTermsModal {
  show: boolean;
  terms: string[];
  title: string;
}

export interface ServiceByWorkshopType {
  serviceCode: string;
  serviceName: string;
  serviceCategoryCode: string;
  workshopTypeCode: string;
}

export interface Service {
  serviceCode: string;
  serviceName: string;
  serviceCategoryCode: string;
  serviceCategoryName?: string;
  ServiceTypeCode: string;
  ServiceTypeName: string;
  //workshopTypeCode?: string;
}

export interface Package {
  workshopServicePackageSetId: string;
  servicePackageDesc: string;
  servicePackageCode: string;
  servicePackageTypeCode?: string;
  servicePackageTypeDesc?: string;
}

export interface Day {
  dayCode: number;
  dayName: string;
}

export interface VehicleType {
  code: string;
  desc: string;
}

export interface VehicleBrand {
  code: string;
  desc: string;
}

export interface VehicleBrandYear {
  code: string;
  desc: string;
}

export interface VehicleModel {
  code: string;
  desc: string;
}

export interface VehicleFuelType {
  code: string;
  desc: string;
}

export interface VehicleDisplacement {
  code: string;
  desc: string;
}

export interface VehicleEquipment {
  code: string;
  desc: string;
}

export interface ServiceCategory {
  serviceCategoryCode: string;
  serviceCategoryName: string;
  serviceTypeCode: string;
}
export interface ServiceType {
  serviceTypeCode: string;
  serviceTypeName: string;
}

export interface AppointmentStatus {
  code: string;
  desc: string;
}

export interface Role {
  roleCode: string;
  roleName: string;
}

export interface VehicleMainPurpose {
  vehicleMainPurposeCode: string;
  vehicleMainPurposeDesc: string;
}

export interface VehicleLicensePlateState {
  vehicleLicensePlateStateCode: string;
  vehicleLicensePlateStateDesc: string;
}

export interface RecommendedServices {
  serviceCode: string;
  serviceName: string;
  orderOfPriority: number;
}

// export interface Product {
//   productName: string;
//   productImageURL: string;
// }
export interface Product {
  productName: string;
  viscosity: string;
  oilType: string;
  mainBenefit: string;
  secondaryBenefit: string;
  productDesc: string;
  photoURL: string;
}

export interface VehicleCatalog {
  code: string;
  desc: string;
  modelYears?: number[];
  brandCode?: string;
}

//Get Calls EnrollmentQuiz

export interface QuizSection {
  sectionName: string;
  sectionCode: string;
  sectionDesc: string;
  questions: QuizQuestions[];
}

export interface QuizQuestions {
  code: string;
  desc: string;
  answers: QuizAnswers[];
}
export interface QuizAnswers {
  code: string;
  desc: string;
}

export interface QuizChecklist {
  sectionDesc: string;
  sectionName: string;
  options: QuizAnswers[];
}

export interface IServiceCarousel {
  serviceTypeCode: string;
  serviceTypeName: string;
  iconPath: string;
  htmlId: string;
  relatedFailures: any[]
  reason: any[]
}



export interface IPackageType {
  servicePackageTypeCode: string;
  servicePackageTypeDesc: string;
  packageItems: {
    packageItemCode: string;
    packageItemDesc: string;
  }[]; 
}
export interface IPackageServiceCombinations {
  servicePackageCode: string;
  servicePackageDesc: string;
  iconPath: string;
  types: IPackageType[];
  searchType: IPackageType;
  htmlID: string;
}
export interface IPackageDetail {
  servicePackageSetId: number;
  servicePackageTypeCode: string;
  servicePackageTypeDesc: string;
  iconPath: string;
  warnings: string;
  items: string[];
}

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface IMunicipalityZone {
  cityId: number;
  cityName: string;
  cityDescription: string;
  cityCopomexName: string;
  municipalities: {
    municipalityId: number;
    municipalityName: string;
    municipalityDescription: string;
    municipalityCopomexName: string;
    zoneMunicipalityName: string;
  }[],
  zones: string[]
}

export interface IPackageCategory {
  servicePackageCode: string;
  servicePackageDesc: string;
  iconPath: string;
  types: IPackageType[];
  searchType: IPackageType;
  htmlID: string;
}
export interface IServiceRalatedFailure {
  srvcTypeFailRelatedId: string;
  srvcTypeFailRelatedDesc: string;
}
export interface IServiceCategory {
  serviceTypeCode: string;
  serviceTypeName: string;
  iconPath: string;
  htmlId: string;
  relatedFailures: IServiceRalatedFailure[]
}

export const municipalityZones : IMunicipalityZone[] = [
  {
    "cityId": 1,
    "cityName": "CDMX",
    "cityDescription": "CDMX y área metropolitana",
    "cityCopomexName": "CDMX",
    "municipalities": [
      {
        "municipalityId": 1,
        "municipalityName": "Gustavo A. Madero",
        "municipalityDescription": "Gustavo A. Madero",
        "municipalityCopomexName": "Gustavo A. Madero",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 2,
        "municipalityName": "Azcapotzalco",
        "municipalityDescription": "Azcapotzalco",
        "municipalityCopomexName": "Azcapotzalco",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 3,
        "municipalityName": "Tlalpan",
        "municipalityDescription": "Tlalpan",
        "municipalityCopomexName": "Tlalpan",
        "zoneMunicipalityName": "CDMX Sur"
      },
      {
        "municipalityId": 4,
        "municipalityName": "Xochimilco",
        "municipalityDescription": "Xochimilco",
        "municipalityCopomexName": "Xochimilco",
        "zoneMunicipalityName": "CDMX Sur"
      },
      {
        "municipalityId": 5,
        "municipalityName": "Coyoacán",
        "municipalityDescription": "Coyoacán",
        "municipalityCopomexName": "Coyoacán",
        "zoneMunicipalityName": "CDMX Sur"
      },
      {
        "municipalityId": 6,
        "municipalityName": "Magdalena Contreras",
        "municipalityDescription": "Magdalena Contreras",
        "municipalityCopomexName": "Magdalena Contreras",
        "zoneMunicipalityName": "CDMX Sur"
      },
      {
        "municipalityId": 7,
        "municipalityName": "Tláhuac",
        "municipalityDescription": "Tláhuac",
        "municipalityCopomexName": "Tláhuac",
        "zoneMunicipalityName": "CDMX Sur"
      },
      {
        "municipalityId": 8,
        "municipalityName": "Iztapalapa",
        "municipalityDescription": "Iztapalapa",
        "municipalityCopomexName": "Iztapalapa",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 9,
        "municipalityName": "Iztacalco",
        "municipalityDescription": "Iztacalco",
        "municipalityCopomexName": "Iztacalco",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 10,
        "municipalityName": "Milpa Alta",
        "municipalityDescription": "Milpa Alta",
        "municipalityCopomexName": "Milpa Alta",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 11,
        "municipalityName": "Miguel Hidalgo",
        "municipalityDescription": "Miguel Hidalgo",
        "municipalityCopomexName": "Miguel Hidalgo",
        "zoneMunicipalityName": "CDMX Poniente"
      },
      {
        "municipalityId": 12,
        "municipalityName": "Cuajimalpa",
        "municipalityDescription": "Cuajimalpa",
        "municipalityCopomexName": "Cuajimalpa",
        "zoneMunicipalityName": "CDMX Poniente"
      },
      {
        "municipalityId": 13,
        "municipalityName": "Álvaro Obregón",
        "municipalityDescription": "Álvaro Obregón",
        "municipalityCopomexName": "Álvaro Obregón",
        "zoneMunicipalityName": "CDMX Poniente"
      },
      {
        "municipalityId": 14,
        "municipalityName": "Cuauhtémoc",
        "municipalityDescription": "Cuauhtémoc",
        "municipalityCopomexName": "Cuauhtémoc",
        "zoneMunicipalityName": "CDMX Centro"
      },
      {
        "municipalityId": 15,
        "municipalityName": "Venustiano Carranza",
        "municipalityDescription": "Venustiano Carranza",
        "municipalityCopomexName": "Venustiano Carranza",
        "zoneMunicipalityName": "CDMX Centro"
      },
      {
        "municipalityId": 16,
        "municipalityName": "Benito Juárez",
        "municipalityDescription": "Benito Juárez",
        "municipalityCopomexName": "Benito Juárez",
        "zoneMunicipalityName": "CDMX Centro"
      },
      {
        "municipalityId": 17,
        "municipalityName": "Tizayuca",
        "municipalityDescription": "Tizayuca",
        "municipalityCopomexName": "Tizayuca",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 18,
        "municipalityName": "Acolman",
        "municipalityDescription": "Acolman",
        "municipalityCopomexName": "Acolman",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 19,
        "municipalityName": "Atenco",
        "municipalityDescription": "Atenco",
        "municipalityCopomexName": "Atenco",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 20,
        "municipalityName": "Atizapán de Zaragoza",
        "municipalityDescription": "Atizapán de Zaragoza",
        "municipalityCopomexName": "Atizapán de Zaragoza",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 21,
        "municipalityName": "Coacalco de Berriozábal",
        "municipalityDescription": "Coacalco de Berriozábal",
        "municipalityCopomexName": "Coacalco de Berriozábal",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 22,
        "municipalityName": "Cuautitlán",
        "municipalityDescription": "Cuautitlán",
        "municipalityCopomexName": "Cuautitlán",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 23,
        "municipalityName": "Cuautitlán Izcalli",
        "municipalityDescription": "Cuautitlán Izcalli",
        "municipalityCopomexName": "Cuautitlán Izcalli",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 24,
        "municipalityName": "Ecatepec de Morelos",
        "municipalityDescription": "Ecatepec de Morelos",
        "municipalityCopomexName": "Ecatepec de Morelos",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 25,
        "municipalityName": "Huixquilucan",
        "municipalityDescription": "Huixquilucan",
        "municipalityCopomexName": "Huixquilucan",
        "zoneMunicipalityName": "CDMX Poniente"
      },
      {
        "municipalityId": 26,
        "municipalityName": "Ixtapaluca",
        "municipalityDescription": "Ixtapaluca",
        "municipalityCopomexName": "Ixtapaluca",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 27,
        "municipalityName": "Jaltenco",
        "municipalityDescription": "Jaltenco",
        "municipalityCopomexName": "Jaltenco",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 28,
        "municipalityName": "La Paz",
        "municipalityDescription": "La Paz",
        "municipalityCopomexName": "La Paz",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 29,
        "municipalityName": "Melchor Ocampo",
        "municipalityDescription": "Melchor Ocampo",
        "municipalityCopomexName": "Melchor Ocampo",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 30,
        "municipalityName": "Naucalpan de Juárez",
        "municipalityDescription": "Naucalpan de Juárez",
        "municipalityCopomexName": "Naucalpan de Juárez",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 31,
        "municipalityName": "Nezahualcóyotl",
        "municipalityDescription": "Nezahualcóyotl",
        "municipalityCopomexName": "Nezahualcóyotl",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 32,
        "municipalityName": "Nextlalpan",
        "municipalityDescription": "Nextlalpan",
        "municipalityCopomexName": "Nextlalpan",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 33,
        "municipalityName": "Nicolás Romero",
        "municipalityDescription": "Nicolás Romero",
        "municipalityCopomexName": "Nicolás Romero",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 34,
        "municipalityName": "Tecámac",
        "municipalityDescription": "Tecámac",
        "municipalityCopomexName": "Tecámac",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 35,
        "municipalityName": "Teoloyucan",
        "municipalityDescription": "Teoloyucan",
        "municipalityCopomexName": "Teoloyucan",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 36,
        "municipalityName": "Tepotzotlán",
        "municipalityDescription": "Tepotzotlán",
        "municipalityCopomexName": "Tepotzotlán",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 37,
        "municipalityName": "Texcoco",
        "municipalityDescription": "Texcoco",
        "municipalityCopomexName": "Texcoco",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 38,
        "municipalityName": "Tlalnepantla de Baz",
        "municipalityDescription": "Tlalnepantla de Baz",
        "municipalityCopomexName": "Tlalnepantla de Baz",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 39,
        "municipalityName": "Tultepec",
        "municipalityDescription": "Tultepec",
        "municipalityCopomexName": "Tultepec",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 40,
        "municipalityName": "Tultitlán",
        "municipalityDescription": "Tultitlán",
        "municipalityCopomexName": "Tultitlán",
        "zoneMunicipalityName": "CDMX Norte"
      },
      {
        "municipalityId": 41,
        "municipalityName": "Valle de Chalco Solidaridad",
        "municipalityDescription": "Valle de Chalco Solidaridad",
        "municipalityCopomexName": "Valle de Chalco Solidaridad",
        "zoneMunicipalityName": "CDMX Oriente"
      },
      {
        "municipalityId": 42,
        "municipalityName": "Zumpango",
        "municipalityDescription": "Zumpango",
        "municipalityCopomexName": "Zumpango",
        "zoneMunicipalityName": "CDMX Norte"
      }
    ],
    "zones": [
      "CDMX Norte",
      "CDMX Sur",
      "CDMX Oriente",
      "CDMX Poniente",
      "CDMX Centro"
    ]
  },
  {
    "cityId": 2,
    "cityName": "Guadalajara",
    "cityDescription": "Guadalajara",
    "cityCopomexName": "Guadalajara",
    "municipalities": [
      {
        "municipalityId": 43,
        "municipalityName": "Guadalajara",
        "municipalityDescription": "Guadalajara",
        "municipalityCopomexName": "Guadalajara",
        "zoneMunicipalityName": "Guadalajara"
      },
      {
        "municipalityId": 44,
        "municipalityName": "Zapopan",
        "municipalityDescription": "Zapopan",
        "municipalityCopomexName": "Zapopan",
        "zoneMunicipalityName": "Guadalajara"
      },
      {
        "municipalityId": 45,
        "municipalityName": "Tonalá ",
        "municipalityDescription": "Tonalá ",
        "municipalityCopomexName": "Tonalá ",
        "zoneMunicipalityName": "Guadalajara"
      },
      {
        "municipalityId": 46,
        "municipalityName": "Tlaquepaque",
        "municipalityDescription": "Tlaquepaque",
        "municipalityCopomexName": "Tlaquepaque",
        "zoneMunicipalityName": "Guadalajara"
      }
    ],
    "zones": [
      "Guadalajara"
    ]
  }
];
